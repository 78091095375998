import React from "react";
import PaymentDetail from "../components/PaymentDetail/PaymentDetail";
import { PaymentContextProvider } from "../components/PaymentDetail/PaymentContext";

const DettagliPagamento = (props) => {
  const { parentUrl, data } = props.pageContext;
  const { labels } = data;

  return (
    <>
      <PaymentContextProvider
        labels={labels}
        parentUrl={parentUrl}
      >
        <PaymentDetail/>
      </PaymentContextProvider>
    </>
  );
};

export default DettagliPagamento;
