import React, { useEffect, useRef, useContext } from "react";
import {
  StyledPaymentDetail,
} from "./style";
import { StyledGridRow } from "../../commons/Grid";
import Loader from "../Loader/Loader";

import { PaymentContext } from "./PaymentContext";

import LoginForm from "./LoginForm";
import PaymentForm from "./PaymentForm";

export default () => {

  const {
    isLoading,
    setIsLoading,
    loadAccessToken,
    showForm,
  } = useContext(PaymentContext);
  //REFS
  const formRef = useRef(null);
  const validation = () => {
    return formRef.current && !formRef.current.reportValidity();
  };

  useEffect(() => {
    setIsLoading(true);
    try {
      loadAccessToken();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  }, []);

  return (
    <StyledPaymentDetail>
      <StyledGridRow noMargin isFull>
        { showForm && (<LoginForm validation={validation}/>)}
        { !showForm && (<PaymentForm validation={validation}/>)}
      </StyledGridRow>
      <Loader isVisible={isLoading} />
    </StyledPaymentDetail>
  );
};
